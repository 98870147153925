import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomerModalComponent } from './customer-modal.component';
import {FormsModule} from "@angular/forms";
import {InputTextModule} from "primeng/inputtext";
import {InputNumberModule} from "primeng/inputnumber";
import {ButtonModule} from "primeng/button";



@NgModule({
  declarations: [
    CustomerModalComponent
  ],
  exports: [
     CustomerModalComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    InputTextModule,
    InputNumberModule,
    ButtonModule
  ]
})
export class CustomerModalModule { }
