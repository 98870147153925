import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {API} from "@aws-amplify/api";

@Component({
  selector: 'app-customer-modal',
  templateUrl: './customer-modal.component.html',
  styleUrls: ['./customer-modal.component.css']
})
export class CustomerModalComponent implements OnInit {

  @Output() closeModal: EventEmitter<boolean>;

  customer = {
    name: '',
    voucher: '',
    usesLeft: null
  }

  constructor() {
    this.closeModal = new EventEmitter<boolean>();
  }

  ngOnInit(): void {
  }

  saveCustomer() {
    let body = {
      customer: this.customer
    }

    API.post('API', 'customer', {body}).then((res: any) => {
      console.log(res);
      this.closeModal.emit(false);
    });
  }
}
