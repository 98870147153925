import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {API} from "@aws-amplify/api";

@Component({
  selector: 'app-permissions-modal',
  templateUrl: './permissions-modal.component.html',
  styleUrls: ['./permissions-modal.component.css']
})
export class PermissionsModalComponent implements OnInit {

  @Input() user;
  @Output() closeModal: EventEmitter<boolean>;


  constructor() {
    this.closeModal = new EventEmitter<boolean>();
  }

  ngOnInit(): void {

  }

  savePermissions() {
    let body = {
      permissions: this.user.permissions,
      accountId: this.user._id,
      storageLimit: this.user.subscription.storageLimit
    };

    API.post('API', 'permission', {body}).then((res: any) => {
      this.closeModal.emit(false);
    });
  }


}
