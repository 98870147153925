<p-table [value]="accounts">
  <ng-template pTemplate="header">
    <tr>
      <th>Name</th>
      <th>Sub</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-account>
    <tr>
      <td>{{account.name}}</td>
      <td>{{account.userName}}</td>
    </tr>
  </ng-template>
</p-table>
