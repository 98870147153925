import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-show-accounts-modal',
  templateUrl: './show-accounts-modal.component.html',
  styleUrls: ['./show-accounts-modal.component.css']
})
export class ShowAccountsModalComponent implements OnInit {

  @Input() accounts;
  @Output() closeModal: EventEmitter<boolean>;

  constructor() {
    this.closeModal = new EventEmitter<boolean>();
  }

  ngOnInit(): void {
  }

}
