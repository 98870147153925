import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PermissionsModalComponent } from './permissions-modal.component';
import {TableModule} from "primeng/table";
import {ButtonModule} from "primeng/button";
import {CheckboxModule} from "primeng/checkbox";
import {FormsModule} from "@angular/forms";
import {InputNumberModule} from "primeng/inputnumber";



@NgModule({
  declarations: [
    PermissionsModalComponent
  ],
  exports: [
    PermissionsModalComponent
  ],
    imports: [
        CommonModule,
        TableModule,
        ButtonModule,
        CheckboxModule,
        FormsModule,
        InputNumberModule
    ]
})
export class PermissionsModalModule { }
