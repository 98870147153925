import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AmplifyUIAngularModule } from '@aws-amplify/ui-angular';
import {TableModule} from "primeng/table";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {ButtonModule} from "primeng/button";
import {NgxFilesizeModule} from "ngx-filesize";
import {DialogModule} from "primeng/dialog";
import {PermissionsModalModule} from "./permissions-modal/permissions-modal.module";
import {CustomerModalModule} from "./customer-modal/customer-modal.module";
import {InputNumberModule} from "primeng/inputnumber";
import {FormsModule} from "@angular/forms";
import {ShowAccountsModalModule} from "./show-accounts-modal/show-accounts-modal.module";
import {DropdownModule} from "primeng/dropdown";
import {ProgressSpinnerModule} from "primeng/progressspinner";
import {BlockUIModule} from "primeng/blockui";

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AmplifyUIAngularModule,
    TableModule,
    NgxFilesizeModule,
    ButtonModule,
    DialogModule,
    PermissionsModalModule,
    CustomerModalModule,
    InputNumberModule,
    FormsModule,
    ShowAccountsModalModule,
    DropdownModule,
    ProgressSpinnerModule,
    BlockUIModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
