import {ChangeDetectorRef, Component, ViewChild} from '@angular/core';
import { onAuthUIStateChange, CognitoUserInterface, AuthState } from '@aws-amplify/ui-components';
import {FilterMetadata, PrimeNGConfig} from "primeng/api";
import {API} from "@aws-amplify/api";
import {$e} from "@angular/compiler/src/chars";
import {Table} from "primeng/table";


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'amplify-angular-auth';
  user: CognitoUserInterface | undefined;
  authState: AuthState | undefined;

  @ViewChild('accountTable', { static: false }) public accountTable: Table;

  accounts: any;
  customers: any;
  rows = 10;
  first = 0;
  loading: any;
  totalAccountRecords = 0;
  totalCustomerRecords = 0;
  displayPermissionsModal = false;
  displayCustomerModal = false;
  permissionsAccount;
  customersTableVisible = true;
  vouchersToAdd: any;
  displayAccountModal = false;
  linkedAccounts;
  userOptions = ['All', 'User', 'Releaser'];
  resultTotal;
  blockScreen = false;

  constructor(private ref: ChangeDetectorRef, private primeConfig: PrimeNGConfig) {
    this.accounts = [];
  }

  ngOnInit() {
    this.primeConfig.ripple = true;
    onAuthUIStateChange((authState, authData) => {
      this.authState = authState;
      this.user = authData as CognitoUserInterface;
      this.ref.detectChanges();

      if (this.authState && this.authState == "signedin") {
        this.loadAccounts();
      }
    });

  }

  ngOnDestroy() {
    return onAuthUIStateChange;
  }

  loadAccounts($event?: any) {
    console.log($event.filters);

    API.get('API', 'account', {queryStringParameters: {rows: $event.rows , first: $event.first,
        sortField: $event.sortField, sortOrder: $event.sortOrder, filters: JSON.stringify($event.filters)}}).then((res: any) => {
      this.accounts = res.accounts;
      console.log(this.accounts);
      this.totalAccountRecords = res.totalRecords;
      this.resultTotal = res.resultTotal;
      this.ref.detectChanges();
    });
  }

  loadCustomers($event?: any) {
    API.get('API', 'customer', {queryStringParameters: {rows: $event.rows , first: $event.first}}).then((res: any) => {
      this.customers = res.customers;
      this.totalCustomerRecords = res.totalRecords;
      this.ref.detectChanges();
    });
  }

  updateCustomerTableVisibility() {
    this.customersTableVisible = false;
    setTimeout(() => this.customersTableVisible = true, 0);
  }


  displayPermissions(account) {
    this.permissionsAccount = account;
    this.displayPermissionsModal = true;
  }

  closePermissionsModal() {
    this.displayPermissionsModal = false;
  }

  displayCustomer() {
    this.displayCustomerModal = true;
  }

  closeCustomerModal() {
    this.updateCustomerTableVisibility();
    this.displayCustomerModal = false;
  }

  addVouchers(customer) {
    console.log(customer);
    console.log(this.vouchersToAdd);

    let body = {
      customerId: customer._id,
      vouchersToAdd: this.vouchersToAdd
    }

    API.patch('API', 'customer', {body}).then((res: any) => {
      this.loadCustomers({first: this.first, rows: this.rows});
      this.updateCustomerTableVisibility();
    });
  }

  displayAccountsModal(customerId) {
    this.displayAccountModal = true;

    API.get('API', 'customerAccounts', {queryStringParameters: {customerId: customerId}}).then((res: any) => {
      this.linkedAccounts = res;
      this.ref.detectChanges();
    });
  }

  closeAccountsModal() {
    this.displayAccountModal = false;
  }

  dropdownChange(value) {
    console.log(value);


    if (value === 'All') {
      this.accountTable.filter(false, 'releaser', 'equals');
      this.accountTable.filter(false, 'user', 'equals');
    } else if (value === 'Releaser') {
      this.accountTable.filter(false, 'user', 'equals');
      this.accountTable.filter(true, 'releaser', 'equals');
    } else if (value === 'User') {
      this.accountTable.filter(false, 'releaser', 'equals');
      this.accountTable.filter(true, 'user', 'equals');
    }
  }

  getUserTypeText(usertypeArray) {
    if (usertypeArray.person) {
      return "Person";
    } else if (usertypeArray.releaser) {
      return "Releaser";
    } else {
      return "Unknown";
    }
  }

  deleteAccount(account: any) {
    // alert("Not yet enabled!");
    // console.log(account);
    //
    // return;

    API.del('API', 'accounts', {queryStringParameters: {accountId: account._id}}).then((res: any) => {
      this.loadAccounts(this.accountTable.createLazyLoadMetadata());
    });
  }
}
