<amplify-auth-container>
  <amplify-authenticator *ngIf="authState !== 'signedin'"></amplify-authenticator>
</amplify-auth-container>

<p-dialog *ngIf="permissionsAccount" header="Permissions - {{permissionsAccount.name}}" [(visible)]="displayPermissionsModal" [modal]="true">
  <app-permissions-modal *ngIf="true" [user]="permissionsAccount" (closeModal)="closePermissionsModal()"></app-permissions-modal>
</p-dialog>

<p-dialog header="New Customer" [(visible)]="displayCustomerModal" [modal]="true">
  <app-customer-modal (closeModal)="closeCustomerModal()"></app-customer-modal>
</p-dialog>

<p-dialog header="Linked Accounts" [(visible)]="displayAccountModal" [modal]="true">
  <app-show-accounts-modal  [accounts]="linkedAccounts" (closeModal)="closeAccountsModal()"></app-show-accounts-modal>
</p-dialog>

<div *ngIf="authState === 'signedin' && user" class="App">
  <amplify-sign-out></amplify-sign-out>
  <div>Hello, {{user.username}}</div>
  <p-table #accountTable [value]="accounts" [lazy]="true" (onLazyLoad)="loadAccounts($event)" responsiveLayout="scroll"
           [paginator]="true" [rows]="rows" [loading]="loading" [totalRecords]="totalAccountRecords">
    <ng-template pTemplate="caption">
      <tr>
        <td>
          <p-dropdown inputId="userTypeDropdown" [options]="userOptions" placeholder="UserType" #userTypeDropdown
                      (onChange)="dropdownChange(userTypeDropdown.value)"></p-dropdown>
        </td>
        <td>
          <p>Total Accounts: {{totalAccountRecords}}</p>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th pSortableColumn="name">Name <p-sortIcon field="name"></p-sortIcon></th>
        <th>Email</th>
        <th>Social</th>
        <th>Tier</th>
        <th pSortableColumn="account._createdAt">Created On <p-sortIcon field="account._createdAt"></p-sortIcon></th>
        <th>UserType</th>
        <th pSortableColumn="lastLogin">Last Login <p-sortIcon field="lastLogin"></p-sortIcon></th>
        <th pSortableColumn="acceptedTC">Accepted T&C <p-sortIcon field="acceptedTC"></p-sortIcon></th>
        <th pSortableColumn="charity">Charity <p-sortIcon field="charity"></p-sortIcon></th>
        <th>Storage Used</th>
        <th></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-account>
      <tr>
        <td>{{account.name}}</td>
        <td>{{account.email}}</td>
        <td *ngIf="account.social">{{account.social.providerName}}</td>
        <td *ngIf="!account.social">N/A</td>
        <td *ngIf="account.usertype.person"><a href="#" (click)="displayPermissions(account)">{{account.subscription.tier}}</a></td>
        <td *ngIf="!account.usertype.person">N/A</td>
        <td>{{account._createdAt | date:'dd-MMM-YYYY'}}</td>
        <td>{{getUserTypeText(account.usertype)}}</td>
        <td>{{account.lastLogin | date:'dd-MMM-YYYY HH:mm'}}</td>
        <td>{{account.lastTOSNotificationDate | date:'dd-MMM-YYYY HH:mm'}}</td>
        <td>{{account.subscription?.charity}}</td>
        <td>{{account.attachmentCount}} - {{account.totalStorage | filesize}}</td>
        <td><button pButton pRipple type="button" label="Delete Account" class="p-button-danger" (click)="deleteAccount(account)"></button></td>
      </tr>
    </ng-template>
    <ng-template pTemplate="footer">
      <tr>
        <td colspan="11">Records Found: {{resultTotal}}</td>
      </tr>
    </ng-template>
  </p-table>


  <p-table *ngIf="customersTableVisible" [value]="customers" [lazy]="true" (onLazyLoad)="loadCustomers($event)" responsiveLayout="scroll"
           [paginator]="true" [rows]="rows" [loading]="loading" [totalRecords]="totalCustomerRecords">
    <ng-template pTemplate="header">
      <tr>
        <th>Name</th>
        <th>Voucher</th>
        <th>Uses Left</th>
        <th>Vouchers Bought</th>
        <th>Accounts Linked</th>
        <th></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-customer>
      <tr>
        <td>{{customer.name}}</td>
        <td>{{customer.voucher}}</td>
        <td>{{customer.usesLeft}}</td>
        <td>{{customer.vouchersBought}}</td>
        <td><a href="#" (click)="displayAccountsModal(customer._id)">{{customer.accountsUsingCode.length}}</a></td>
        <td>
          <p-inputNumber [(ngModel)]="vouchersToAdd"></p-inputNumber>
          <p-button (onClick)="addVouchers(customer)">Add Vouchers</p-button>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
<p-button (onClick)="displayCustomer()">New Customer</p-button>
