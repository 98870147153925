<table style="width: 100%">
  <tr>
    <td>Name</td>
    <td style="text-align: right"><input type="text" pInputText [(ngModel)]="customer.name"></td>
  </tr>
  <tr>
    <td>Voucher</td>
    <td style="text-align: right"><input type="text" pInputText [(ngModel)]="customer.voucher"></td>
  </tr>
  <tr>
    <td>Uses</td>
    <td style="text-align: right"><p-inputNumber [(ngModel)]="customer.usesLeft"></p-inputNumber></td>
  </tr>
</table>

<p-button (onClick)="saveCustomer()">Save</p-button>
