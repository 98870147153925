import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShowAccountsModalComponent } from './show-accounts-modal.component';
import {TableModule} from "primeng/table";



@NgModule({
    declarations: [
        ShowAccountsModalComponent
    ],
    exports: [
        ShowAccountsModalComponent
    ],
    imports: [
        CommonModule,
        TableModule
    ]
})
export class ShowAccountsModalModule { }
