<table style="width: 100%">
  <tr>
    <th>Name</th>
    <th>Value</th>
  </tr>
  <tr>
    <td>Push Notifications</td>
    <td style="text-align: right"><p-checkbox [(ngModel)]="user.permissions.pushNotifications" [binary]="true"></p-checkbox></td>
  </tr>
  <tr>
    <td>Upload</td>
    <td style="text-align: right"><p-checkbox [(ngModel)]="user.permissions.upload" [binary]="true"></p-checkbox></td>
  </tr>
  <tr>
    <td>Releaser</td>
    <td style="text-align: right"><p-checkbox [(ngModel)]="user.permissions.releaser" [binary]="true"></p-checkbox></td>
  </tr>
  <tr>
    <td>PDF Export Unlimited</td>
    <td style="text-align: right"><p-checkbox [(ngModel)]="user.permissions.pdfExportUnlimited" [binary]="true"></p-checkbox></td>
  </tr>
  <tr>
    <td>Email</td>
    <td style="text-align: right"><p-checkbox [(ngModel)]="user.permissions.email" [binary]="true"></p-checkbox></td>
  </tr>
  <tr>
    <td><label for="storageLimitInput">Storage Limit</label></td>
    <td style="text-align: right"><p-inputNumber [(ngModel)]="user.subscription.storageLimit" [showButtons]="true" [step]="1073741824" inputId="stacked" id="storageLimitInput"> </p-inputNumber></td>
  </tr>
</table>

<p-button (onClick)="savePermissions()">Save</p-button>
