// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  auth: {
    identityPoolId: 'ca-central-1:df4a6de6-93c2-479e-ae12-b3fc8b469b8a',
    region: 'ca-central-1',
    userPoolId: 'ca-central-1_SwT2h3YrA',
    userPoolWebClientId: '17jpeuja3mh0m4u1c8o866g5pg'
  },
  API: {
    endpoints: [
      {
        name: 'API',
        endpoint: 'https://9cwjch5o8d.execute-api.ca-central-1.amazonaws.com/development/',
        region: 'ca-central-1'
      }
    ]
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
